<template>
  <Page class="page-shop page-shop-settings page-shop-settings-invoice" v-if="settings">
    <template #title>Dati di fatturazione</template>

    <template #title-extra>
      <router-link v-if="isEdit" class="btn btn-outline-light" :to="{ name: 'shop.settings.invoice' }">Annulla modifica</router-link>
      <router-link v-else class="btn btn-outline-light" :to="{ name: 'shop.settings.invoice.edit', params: { edit: 'edit' } }">Modifica</router-link>
    </template>

    <template #default>
      <ShopInvoiceForm v-if="isEdit" :feedback.sync="feedback" :settings="settings" @submitForm="submit" edit />
      <template v-else>
        <p>Ragione sociale: {{ settings.company_name }}</p>
        <p v-if="settings.vat_code">Partita IVA: {{ settings.vat_code }}</p>
        <p v-if="settings.tax_code">Codice fiscale: {{ settings.tax_code }}</p>
        <p>Iban: {{ settings.iban }}</p>
        <p>Fatturazione elettronica: <template v-if="settings.invoice_mode === 'sdi'">SDI {{ settings.sdi }}</template><template v-else>PEC {{ settings.pec }}</template></p>
        <p>Email per le comunicazioni di fatturazione: <template v-if="settings.main_email_as_invoice_email">Email generale ({{ settings.real_invoice_email }})</template><template v-else>{{ settings.real_invoice_email }}</template></p>
        <p>Abilitare il pagamento diretto al negozio? <template v-if="settings.allow_direct_payment">Sì</template><template v-else>No</template></p>
        <p>Email per le comunicazioni di pagamento: <template v-if="settings.main_email_as_receipt_email">Email generale ({{ settings.real_receipt_email }})</template><template v-else>{{ settings.real_receipt_email }}</template></p>
        <p>Codice di riscatto: {{ settings.redeem_code }}</p>
      </template>
    </template>
  </Page>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import Page from '@/views/components/Private/Page.vue';
import ShopInvoiceForm from '@/views/components/Form/ShopInvoiceSettingsForm.vue';

export default {
  components: {
    Page,
    ShopInvoiceForm,
  },
  data: () => ({
    settings: null,
    feedback: null,
  }),
  computed: {
    isEdit () {
      return this.$route?.params?.edit;
    },
  },
  methods: {
    submit (settings) {
      this.feedback = null;

      this.$api.updateMyShopInvoiceSettings(settings)
        .then(res => {
          this.settings = res.data;
          this.$router.replace({ name: 'shop.settings.invoice' });
          this.$oauth2.refreshMe();
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.feedback = null;
  },
  beforeRouteEnter (to, from, next) {
    $api.getMyShopInvoiceSettings()
      .then(res => res.data)
      .then(data => {
        if (!data) {
          return next(from);
        }

        next(vm => {
          vm.settings = data;
        });
      })
      .catch(() => next(from))
    ;
  },
};

</script>
